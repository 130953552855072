<!--
* @description:
* @fileName passConfig.vue
* @author hvv
* @date 2023/02/01 11:04:56
!-->
<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>馨服务发放数量统计</span>
        </div>
      </template>
      <el-row :gutter="20">
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <vab-card class="order-card1" shadow="hover">
            <template #header>已领取数量（个）:</template>
            <div class="bottom">
              <span>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="data.receiveNum"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </span>
            </div>
          </vab-card>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <vab-card class="order-card1" shadow="hover">
            <template #header>剩余数量（个）:</template>
            <div class="bottom">
              <span>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="data.surplusNum"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </span>
            </div>
          </vab-card>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24" :xl="8" :xs="24">
          <vab-card class="order-card1" shadow="hover">
            <template #header>总投放数量（个）:</template>
            <div class="bottom">
              <span>
                <vab-count
                  :decimals="countConfig.decimals"
                  :duration="countConfig.duration"
                  :end-val="data.quantity"
                  :prefix="countConfig.prefix"
                  :separator="countConfig.separator"
                  :start-val="countConfig.startVal"
                  :suffix="countConfig.suffix"
                />
              </span>
            </div>
          </vab-card>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>馨服务发放人员统计</span>
        </div>
      </template>
      <vab-query-form style="padding-top: 20px">
        <vab-query-form-right-panel :span="24">
          <el-form :inline="true" :model="queryForm" @submit.prevent>
            <el-form-item label="客户姓名">
              <el-input v-model="queryForm.name" placeholder="请输入客户姓名" />
            </el-form-item>
            <el-form-item
              label="客户手机号"
              style="margin: 0 10px 10px 10px !important"
            >
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入客户手机号"
              />
            </el-form-item>

            <el-form-item>
              <el-button :icon="Search" type="primary" @click="searchPassData">
                查询
              </el-button>
              <el-button :icon="Search" type="primary" @click="_exportPass">
                导出
              </el-button>
              <el-button
                :icon="Search"
                type="primary"
                @click="_exportPassHasPhone"
              >
                完整导出
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-right-panel>
      </vab-query-form>
      <el-tabs
        v-model="queryForm.status"
        class="demo-tabs"
        @tab-click="handleClick"
      >
        <el-tab-pane label="待审核" name="PENDING_REVIEW" />
        <el-tab-pane label="审核成功" name="ACCEPT" />
        <el-tab-pane label="审核不通过" name="REFUSE" />
      </el-tabs>
      <el-table v-loading="listLoading" border :data="list">
        <el-table-column label="客户姓名" prop="name" show-overflow-tooltip />
        <el-table-column
          label="客户手机号"
          prop="phone"
          show-overflow-tooltip
        />
        <el-table-column label="客户身份">
          <template #default="{ row }">
            <span v-if="row.level == 'DIAMOND'">钻石</span>
            <span v-else>翡翠</span>
          </template>
        </el-table-column>
        <el-table-column
          label="公司信息"
          prop="company"
          show-overflow-tooltip
        />
        <el-table-column label="职位" prop="position" show-overflow-tooltip />
        <el-table-column label="出发地">
          <template #default="{ row }">
            <span v-if="row.country == '中国'">
              {{ row.country }}-{{ row.province }}-{{ row.city }}
            </span>
            <span v-else>{{ row.country }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="杭州目的地"
          prop="targetArea"
          show-overflow-tooltip
        />
        <el-table-column label="来杭目的" prop="target" show-overflow-tooltip />

        <el-table-column label="审核状态" prop="status">
          <template #default="{ row }">
            <el-tag v-if="row.status == 'ACCEPT'" type="success">
              审核通过
            </el-tag>
            <el-tag v-if="row.status == 'REFUSE'" type="danger">
              审核不通过
            </el-tag>
            <el-tag v-if="row.status == 'PENDING_REVIEW'" type="warning">
              待审核
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column label="提交时间" prop="reviewTime">
          <template #default="{ row }">
            <span>{{ handleDate(row.createTime) }}</span>
          </template>
        </el-table-column>

        <template #empty>
          <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.pageNumber"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </el-card>

    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>馨服务优惠券发放统计</span>
        </div>
      </template>
      <vab-query-form style="padding-top: 20px">
        <vab-query-form-right-panel :span="24">
          <el-form :inline="true" :model="queryForm" @submit.prevent>
            <!--<el-form-item label="客户姓名">
              <el-input v-model="queryForm.name" placeholder="请输入客户姓名" />
            </el-form-item>
            <el-form-item
              label="客户手机号"
              style="margin: 0 10px 10px 10px !important"
            >
              <el-input
                v-model="queryForm.phone"
                placeholder="请输入客户手机号"
              />
            </el-form-item> -->

            <el-form-item>
              <!-- <el-button :icon="Search" type="primary" @click="searchPassData">
                查询
              </el-button> -->
              <el-button
                :icon="Search"
                type="primary"
                @click="_exportCouponList"
              >
                导出
              </el-button>
              <el-button
                :icon="Search"
                type="primary"
                @click="_exportCouponListHasPhone"
              >
                完整导出
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-right-panel>
      </vab-query-form>

      <!-- <vab-query-form style="padding-top: 20px">
        <vab-query-form-right-panel :span="24">
          <el-form :inline="true" :model="queryForm1" @submit.prevent>
            <el-form-item label="客户姓名">
              <el-input
                v-model="queryForm1.name"
                placeholder="请输入客户姓名"
              />
            </el-form-item>
            <el-form-item>
              <el-button
                :icon="Search"
                type="primary"
                @click="searchCouponData"
              >
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-right-panel>
      </vab-query-form> -->

      <el-table v-loading="listLoading1" border :data="list1">
        <el-table-column label="客户姓名" prop="name" show-overflow-tooltip />
        <el-table-column
          label="客户手机号"
          prop="phone"
          show-overflow-tooltip
        />
        <el-table-column label="客户身份">
          <template #default="{ row }">
            <span v-if="row.level == 'DIAMOND'">钻石</span>
            <span v-else>翡翠</span>
          </template>
        </el-table-column>
        <el-table-column
          label="发券权益内容"
          prop="couponName"
          show-overflow-tooltip
        />
        <el-table-column label="发送时间" prop="reviewTime">
          <template #default="{ row }">
            <span>{{ handleDate(row.sendTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发送状态" prop="status">
          <template #default="{ row }">
            <el-tag v-if="row.status == 1" type="success">发送成功</el-tag>
            <el-tag v-if="row.status == 0" type="danger">发送失败</el-tag>
          </template>
        </el-table-column>
        <template #empty>
          <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm1.pageNumber"
        :layout="layout"
        :page-size="queryForm1.pageSize"
        :total="total1"
        @current-change="handleCurrentChange1"
        @size-change="handleSizeChange1"
      />
    </el-card>
  </div>
</template>

<script>
  import VabCount from '@/extra/VabCount'
  import { defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
  import {
    getPassConfig,
    getPassList,
    getCouponList,
    exportPassList,
    exportCouponList,
  } from '@/api/passApply'
  import { parseTime } from '@/utils/index'
  export default defineComponent({
    name: 'passConfig.vue',
    components: { VabCount },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        countConfig: {
          startVal: 0,
          endVal: 888,
          decimals: 0,
          prefix: '',
          suffix: '',
          separator: ',',
          duration: 3000,
        },
        form: {
          receiveRule: '',
        },
        queryForm: {
          name: '',
          pageNum: 1,
          pageSize: 10,
          phone: '',
          level: '',
          status: 'ACCEPT',
        },
        data: {},
        labelPosition: 'top',
        formRef: null,
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        queryForm1: {
          name: '',
          pageNum: 1,
          pageSize: 10,
        },
        total1: 0,
        listLoading1: false,
        list1: [],
      })

      const fetchConfig = async () => {
        const { data } = await getPassConfig()
        state.data = Object.assign({}, data)
        state.form.receiveRule = data.receiveRule
        state.form.quantity = data.surplusNum
      }

      const fetchData = async () => {
        state.listLoading = true
        let { data } = await getPassList(state.queryForm)
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }
      const fetchData1 = async () => {
        state.listLoading1 = true
        let { data: couponData } = await getCouponList(state.queryForm1)
        state.list1 = couponData.data
        state.total1 = couponData.total
        state.listLoading1 = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        queryData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNum = val
        queryData()
      }

      const handleSizeChange1 = (val) => {
        state.queryForm1.pageSize = val
        queryData1()
      }
      const handleCurrentChange1 = (val) => {
        state.queryForm1.pageNum = val
        queryData1()
      }

      const handleDate = (val) => {
        return parseTime(val)
      }
      const queryData = () => {
        fetchData()
      }
      const queryData1 = () => {
        fetchData1()
      }

      const searchCouponData = () => {
        state.queryForm1.pageNum = 1
        state.queryForm1.pageSize = 10
        fetchData1()
      }

      const searchPassData = () => {
        state.queryForm.pageNum = 1
        state.queryForm.pageSize = 10
        fetchData()
      }

      const _exportPass = async () => {
        let _exportParam = {
          pageNum: 1,
          pageSize: 99999,
          status: state.queryForm.status,
        }
        const { data } = await exportPassList(_exportParam)
        window.open(data.fileUrl)
      }
      const _exportCouponList = async () => {
        let _exportParam = {
          name: '',
          pageNum: 1,
          pageSize: 999999,
        }
        const { data } = await exportCouponList(_exportParam)
        window.open(data.fileUrl)
      }

      const _exportCouponListHasPhone = async () => {
        let _exportParam = {
          name: '',
          pageNum: 1,
          pageSize: 999999,
          isShowPhone: true,
        }
        const { data } = await exportCouponList(_exportParam)
        window.open(data.fileUrl)
      }

      const _exportPassHasPhone = async () => {
        let _exportParam = {
          pageNum: 1,
          pageSize: 99999,
          status: state.queryForm.status,
          isShowPhone: true,
        }
        const { data } = await exportPassList(_exportParam)
        window.open(data.fileUrl)
      }

      const handleClick = (val) => {
        console.log(val)
        queryData()
      }

      fetchConfig()
      fetchData()
      fetchData1()
      return {
        ...toRefs(state),
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleSizeChange1,
        handleCurrentChange1,
        handleDate,
        searchCouponData,
        searchPassData,
        handleClick,
        _exportPass,
        _exportPassHasPhone,
        _exportCouponList,
        _exportCouponListHasPhone,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
